import React, { useEffect, useState } from "react";
import { Toast } from "primereact/toast";
import { validateAddSystem } from "../../../../helpers/commonHelper";
import {
  getSpecificSystem,
  updateEntity,
} from "../../../../services/apiService";
import { useParams, useNavigate } from "react-router-dom";

const EditSystem = (props) => {
  const [entityOptions, setEntityOptions] = useState({});
  const [delfautlOptions, setDelfautlOptions] = useState({
    entity_name: "",
    entity_description: "",
    entity_type: "",
  });
  const params = useParams();
  let navigate = useNavigate();
  const toast = React.useRef(null);

  useEffect(() => {
    if (params && params.systemId) {
      fetchSpecificSystems(params.systemId);
    }
  }, [params, params.systemId]);

  const fetchSpecificSystems = async () => {
    try {
      let response = await getSpecificSystem(params.systemId);

      if (response.status === 200) {
        let data = response.data || {};
        setDelfautlOptions({
          entity_name: data.entity_name,
          entity_description: data.entity_description,
          entity_type: data.entity_type,
        });
        setEntityOptions({
          ...data.entity_data,
        });
      }
    } catch (error) {
    } finally {
    }
  };

  const showToast = (type, summary, details) => {
    toast.current.show({
      severity: type || "success",
      summary: summary || "Success Message",
      detail: details || "Message Content",
      life: 3000,
    });
  };
  const handleEntityOptionChange = (e, item) => {
    const name = e.target.name;
    const value = e.target.value;
    const tempObj = { ...entityOptions };
    tempObj[item][name] = value;
    setEntityOptions(tempObj);
  };

  // default option changes handler Name and description
  const handleDefaultOptionChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDelfautlOptions({ ...delfautlOptions, [name]: value });
  };
  const handleSaveandClose = async () => {
    try {
      const data = {
        ...delfautlOptions,
        entity_data: entityOptions,
      };
      const errors = validateAddSystem(data);
      if (errors.length) {
        let errList = errors.map((err) => ({
          severity: "error",
          summary: `${err}`,
          life: 3000,
        }));
        toast.current.show(errList);
        return;
      }
      let response = await updateEntity(params.systemId, data);
      if (response.status === 200) {
        showToast(
          "success",
          "Entity updated",
          response.data.message || "Entity was updated successfully."
        );
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    } catch (error) {
      showToast("error", "", error.message);
    }
  };
  return (
    <div className="add-systems">
      <button
        className="btn btn-icon-primary btn-icon"
        onClick={() => navigate(-1)}
      >
        <i className="pi pi-arrow-left"></i>
      </button>
      <div className="sidebar-title">Edit System</div>
      <div className="form-options">
        <div className="form_group">
          <label className="label">{"Name"}</label>
          <input
            type="text"
            name="entity_name"
            value={delfautlOptions.entity_name}
            className="form-control"
            placeholder={"Enter system name"}
            onChange={(e) => handleDefaultOptionChange(e)}
          />
        </div>
        <div className="form_group">
          <label className="label">{"Description"}</label>
          <input
            type="text"
            name="entity_description"
            value={delfautlOptions.entity_description}
            className="form-control"
            placeholder={"Enter system description"}
            onChange={(e) => handleDefaultOptionChange(e)}
          />
        </div>
        {Object.keys(entityOptions).map((item, index) => (
          <div className="form_group" key={index}>
            <label className="label">Enter {item} Details Below:</label>
            {Object.keys(entityOptions[item])?.map((key, ind) => {
              return <div className="form_group" key={ind}>
                <label className="label">Enter {key}</label>
                <input
                  type="text"
                  name={key}
                  className="form-control"
                  value={entityOptions[item][key]}
                  placeholder={`Enter ${ind}`}
                  onChange={(e) => handleEntityOptionChange(e, item)}
                />
              </div>
            })}

          </div>
        ))}
      </div>

      <button
        className="btn btn-primary"
        onClick={handleSaveandClose}
        style={{ marginRight: "auto" }}
      >
        Save and Close
      </button>
      <Toast ref={toast} position="top-right"></Toast>
    </div >
  );
};

export default EditSystem;
