import React, {useEffect, useState} from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';

const SysByGrowth = (props) => {
    const [selectedSystem, setSelectedSystem] = useState(null);
    const [sysData, setSysData] = useState([]);
    const [value, setValue] = useState(null);
    const items = [
        { name: 'Show Potential Systems', value: 1 },
        { name: 'Show All Systems', value: 2 }
    ];

    const setSys = (sys) => {
        props.setData(sys);
        setSelectedSystem(sys);
    }

    const getData = (e) => {
        if(e === 1){
            const data = props.data && props.data.filter((d) => {
                return d.perc_change >= (props.params.perc_growth - 0.5) && d.perc_change <= (props.params.perc_growth + 0.5)
            });
            setSysData(data);    
        }else{
            setSysData(props.data)
        }
        setValue(e)
    }

    useEffect(()=> {
        setSysData(props.data)
    },[props.data])

    return (
        <div className="build_para_card_sys">
            <div className="header">
                <section className="sectionA">
                    <div className="build_title">Proposed No. of Systems Shown : {props.data?.length}</div>
                    <div className="build_title">Based on Potential for Growth (PFG) percentage : {props.params.perc_growth}</div>
                    <div className="build_title">Peak CPW : {props.peak} </div>
                </section>
                <section className="sectionB">
                    <SelectButton value={value} onChange={(e) => getData(e.value)} optionLabel="name" options={items} />
                </section>
            </div>
            <div className="systems">
            <DataTable value={sysData} 
                selectionMode={true} 
                selection={selectedSystem} 
                onSelectionChange={(e) => setSys(e.value)} 
                dataKey="m_id" 
                tableStyle={{ minWidth: '80rem' }}
                paginator rows={5} 
                rowsPerPageOptions={[5, 10, 25, 50]}>
                <Column field="m_ptype" header="Type" sortable />
                <Column field="m_model" header="Model" sortable />
                <Column field="m_cpw" header="CPW Rating (Frame)" sortable />
                <Column field="m_ncores" header="Total Frame Cores" sortable />
                <Column field="proposed_lpar_cores" header="Proposed LPAR Cores" sortable />
                <Column field="proposed_cpw" header="Proposed CPW Based on PFG" sortable />
                <Column field="perc_change" header="Actual Growth Capacity %" sortable />
                <Column selectionMode="single" headerStyle={{ width: '3rem' }} />
            </DataTable>
            </div>
        </div>
    )
}

export default SysByGrowth;