import React, { useState, useEffect, useRef } from 'react';
import CanvasJSReact from "../../../scripts/canvasjs.react";
import moment from "moment";
import { separateComma } from '../../../helpers/commonHelper';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function ChartViewPoolTrends(props) {
  var chartRef = useRef();
  const { poolData } = props;
  function toolTipContent(e){
	var str = "";
	var total = 0;
	var str2, str3;
	for (var i = 0; i < e.entries.length; i++){
		var  str1 = "<span style= \"color:"+e.entries[i].dataSeries.color + "\"> "+e.entries[i].dataSeries.name+"</span>: $<strong>"+e.entries[i].dataPoint.y+"</strong>bn<br/>";
		total = e.entries[i].dataPoint.y + total;
		str = str.concat(str1);
	}
	str2 = "<span style = \"color:DodgerBlue;\"><strong>"+(e.entries[0].dataPoint.x).getFullYear()+"</strong></span><br/>";
	total = Math.round(total * 100) / 100;
	str3 = "<span style = \"color:Tomato\">Total:</span><strong> $"+total+"</strong>bn<br/>";
	return (str2.concat(str)).concat(str3);
 }

  const options = {
    animationEnabled: true,
	title:{
		text: "Top Pools",
		fontWeight: "bolder",
        fontColor: "#000",
        fontFamily: "tahoma",
        fontSize: 20,
        padding: 10,
	},
	axisY:{
        title: "Faults / Sec",
    valueFormatString: "#,##0.##",
    ...(props.maximum && { maximum: props.maximum }),
    ...((props.minimum || props.minimum === 0) && {
    minimum: props.minimum,
    }),
	},
	toolTip: {
		shared: true
	},
	data: poolData
  };

  return (
    <div className={"chat_main"}>
      <CanvasJSChart
        options={options}
        onRef={(ref) => {
          chartRef.current = ref;
        }}
      />
      {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
    </div>
  );
}

export default ChartViewPoolTrends;