import React,{useState} from "react";
import { useSearchParams } from "react-router-dom";
import { Table } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { handlePrintDetails } from "../../helpers/commonHelper";
import { renderCommonPdMemory, getPdMemoryStatus } from "../../helpers/summaryHelpers";
import CanvasJSReact from '../../scripts/canvasjs.react';
import { ReportDetailsTableJSON, TopJobCPU } from "../../utils/constant";
import SectionHeader from "../components/SectionHeader";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import TopJobsData from "../../assets/top_jobs_data.json"
import chartDataMemory from "../../assets/pie_data_memory.json"
import tableDataMemeory from "../../assets/table_data_memory.json"
import * as _ from "lodash"

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function ReportDetails() {
  let [searchParams] = useSearchParams();
  const serverId = searchParams.get("id");
  const host = searchParams.get("host");
  const viewType = searchParams.get("viewType");
  const [activeInternalTabID, setInternalActiveTabID] = useState(5);
  const [activeTabID, setActiveTabID] = useState(1);
  const [filters, setFilters] = useState({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  let breadCrumbsList = [
    {
      id: 1,
      name: "Enterprise",
      url: "/enterprise-server",
    },
    {
      id: 2,
      name: serverId,
      url: `/server?id=${serverId}`,
    },
    {
      id: 3,
      name: host,
      url: `/metrics?id=${serverId}&host=${host}`,
    },
    {
      id: 4,
      name: viewType,
      url: ``,
    },
  ];

  const ViewTypeList = {
    "CPU-ms": "CPU ms", 
    "Number-of-Cores": "No. of Cores", 
    "Faulting-Rate": "Faulting Rate (Faults/Sec)", 
    "cache_hit_perc": "Cache Hit Percentage", 
    "Total-Disk-Operations": "Total Disk Operations", 
    "CPU-Utilization": "CPU Utilization", 
    "read_write_ratio": "Read Write Ration", 
    "executing_summary": "Executive Summary", 
    "Disk-Response-Time": "Disk Response Time", 
    "pool_faulting_rate": "Pool Faulting Rate", 
    "5250-Response-Time": "5250 Response Time", 
    "Total-Transactions": "Total Transactions", 
    "Disk-Arm-Utilization": "Disk Arm Utilization", 
    "memory_size_faulting": "Memory vs Size Faulting", 
    "top_jobs_utilisation": "Top Jobs Utilization", 
    "machine_pool_faulting": "Machine Pool Faulting", 
    "system_specifications": "System Specifications", 
    "Disk-Space-Utilization": "Disk Space Utilization", 
    "top_pool_faulting_rate": "Top Pool Faulting Rate", 
    "Ethernet-Line-Utilization": "Ethernet Line Utilization",
    "CPW-Utilization":"CPW Utilization"
  }
  
  const optionsMemory = {
    exportEnabled: true,
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Percentage Breakdown ",
      fontSize: 15,
    },
    legend: {
      verticalAlign: "center", 
      horizontalAlign: "left",
    },
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y} %",
        showInLegend: "true",
        legendText: "{label} - {y}%",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: chartDataMemory.map(({ total, jobname }) => ({
          y: +total,
          label: jobname,
        })),
      },
    ],
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  }

  const summaryData = _.filter(ReportDetailsTableJSON, function(o) { 
    return o.metric_type == viewType; 
  });

  const renderHeader = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <InputText  style={{ maxWidth: "15rem" }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" className="form-control"/>
      </div>
    );
  };

  const header = renderHeader();
  return (
    <>
    <div className="report_details_wrapper">
      <SectionHeader
        title={`Details -  ${ViewTypeList[viewType]}`}
        btnClickHandler={handlePrintDetails}
        breadCrumbsList={breadCrumbsList}
      />
      <div className="table_wrapper">
        <Table bordered>
          <thead>
            <tr>
              <th>Metric</th>
              <th>Status</th>
              <th>Findings</th>
            </tr>
          </thead>
          <tbody>
            {summaryData.map((data) => {
              return (
                <tr key={data.id}>
                  <td>{ViewTypeList[viewType]}</td>
                  <td>
                    <span className={data.metric_status}>
                      {data.metric_health}
                    </span>
                  </td>
                  <td className="findings_col">
                    {data.findings.map((d) => (
                      <div key={d.id} className={d.type}>
                        {d.msg}
                      </div>
                    ))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
  </div>
  <div style={{display: "flex"}}>
  <div className="topjobs-conatiner" >
        <div>
          <DataTable
              value={TopJobsData}
              header={header}
              paginator
              className="systems-table"
              showGridlines
              rows={10}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              dataKey="id"
              filterDisplay="menu"
              responsiveLayout="scroll"
              filters={filters}
              globalFilterFields={[
                "jbname",
                "jbuser",
                "jbnbr",
                "cpu",
                "totalios",
                "syncios",
                "asyncios",
                "faults",
              ]}
              emptyMessage="No Jobs Found"
            >
              <Column header="Job Name" field="jbname" sortable></Column>
              <Column header="User" field="jbuser" sortable></Column>
              <Column header="Job Num" field="jbnbr" sortable></Column>
              <Column header="Faults" field="faults" sortable></Column>
            </DataTable>
        </div>
    </div>
    <div className="topjobs-conatiner">
        <TabContent activeTab={String(activeTabID)}>
          <TabPane tabId="1">
            {/* inside pie  tabs  under performance insights starts */}
            <Nav tabs className="pie-inside-tabbing">    
                <NavItem>
                  <NavLink
                    className={activeInternalTabID === 5 ? "active" : ""}
                    onClick={() => setInternalActiveTabID(5)}
                  >
                    Faults
                  </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={String(activeInternalTabID)}>
              <TabPane tabId="1">
                <div className="pie-chart-wrapper">
                    
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="pie-chart-wrapper">
                    
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="pie-chart-wrapper">
                    
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="pie-chart-wrapper">
                    
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="pie-chart-wrapper">
                  <CanvasJSChart options={optionsMemory} title={"Faults"} />
                </div>
              </TabPane>
            </TabContent>
          </TabPane>
        </TabContent>
      </div>
  </div>
  <div className="topjobs-container">
        <DataTable
           value={tableDataMemeory}
           stripedRows
           showGridlines
           responsiveLayout="scroll"
         >
           <Column
             field="pool"
             header="Memory Pool"
             align="center"
             style={{width:"264px"}}
           ></Column>
           <Column
             align="center"
             field={(data)=>renderCommonPdMemory(data,data.memory_size)}
             header="Memory (GB)"
           ></Column>
           <Column
             align="center"
             field={(data)=>renderCommonPdMemory(data,data.mem_perc)}
             header="% of Memory in Pool"
           ></Column>
           <Column
            align="center"
            field={(data)=> renderCommonPdMemory(data,data.faulting_rate)}    
             header="Average Faulting Rate (Faults / Sec)"
           ></Column>
         </DataTable>
      </div>
      <p></p>
  </>
  );
}

export default ReportDetails;
