import React from "react";
import { Link } from "react-router-dom";

function SettingHeader(props) {
  const { iconClass, title, subTitle, pLink } = props;
  return (
    <div className="setting-header">
      <div className="settion-header-left">
        <div className="setting-icon">
          {iconClass && <i className={iconClass}></i>}
        </div>
        <div className="setting-header-details">
          {title && <div className="setting-header-title">{title}</div>}
          {subTitle && (
            <div className="setting-header-subtitle">{subTitle}</div>
          )}
        </div>
      </div>
      {pLink && 
        <div className="settion-header-right">
        <Link to={pLink} target="_blank" rel="noopener noreferrer">
          <button type="btn" className="btn btn-primary">
              <i className="fa fa-print"></i> Print
            </button>
        </Link>
        </div>
      }
    </div>
  );
}

export default SettingHeader;
