import React from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { handlePrintDetails } from "../../helpers/commonHelper";
import MetricsCard from "../components/MetricsCard";
import SectionHeader from "../components/SectionHeader";
function MetricsPage() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const serverId = searchParams.get("id");
  const host = searchParams.get("host");

  const handleNavigation = (serverName, page) => {
    let myString = serverName;
    myString = myString.replace(/\s+/g, "-");
    const params = { id: serverId, host: host, viewType: myString };
    navigate({
      pathname: `${page}`,
      search: `?${createSearchParams(params)}`,
    });
  };
  let breadCrumbsList = [
    {
      id: 1,
      name: "Enterprise",
      url: "/enterprise-server",
    },
    {
      id: 2,
      name: serverId,
      url: `/server?id=${serverId}`,
    },
    {
      id: 3,
      name: host,
      url: ``,
    },
  ];
  let arr = [
    {
      id: 1,
      aaaaa: "10-1421",
      type: "critical",
      msg: "Critical",
      utilization: "91%",
      metricsName: "CPU Utilization",
      page:"/detailed-view-cpu"
    },
    {
      id: 2,
      aaaaa: "10-2751",
      type: "info",
      msg: "Info",
      utilization: "0.19",
      metricsName: "Number of Cores",
      page:"/detailed-view-cpu"
    },
    {
      id: 3,
      aaaaa: "11-9432",
      type: "info",
      msg: "Info",
      utilization: "118,266.34 ms",
      metricsName: "CPU ms",
      page:"/detailed-view-cpu"
    },
    {
      id: 4,
      aaaaa: "10-1421",
      type: "critical",
      msg: "Critical",
      utilization: "8.4 ms",
      metricsName: "5250 Response Time",
      page:"/detailed-view-cpu"
    },
    {
      id: 5,
      aaaaa: "10-1421",
      type: "info",
      msg: "Info",
      utilization: "810",
      metricsName: "Total Transactions",
      page:"/detailed-view-cpu"
    },
    {
      id: 6,
      aaaaa: "10-2751",
      type: "good",
      msg: "good",
      utilization: "10%",
      metricsName: "Disk Space Utilization",
      page:"/detailed-view-disk"
    },
    {
      id: 7,
      aaaaa: "11-9432",
      type: "good",
      msg: "good",
      metricsName: "Disk Arm Utilization",
      utilization: "14%",
      page:"/detailed-view-disk"
    },
    {
      id: 8,
      aaaaa: "10-1421",
      type: "warning",
      msg: "warning",
      metricsName: "Disk Response Time",
      utilization: "34 ms",
      page:"/detailed-view-disk"
    },
    {
      id: 9,
      aaaaa: "10-1421",
      type: "info",
      metricsName: "Total Disk Operations",
      msg: "Info",
      utilization: "720 ops/sec",
      page:"/detailed-view-disk"
    },
    {
      id: 10,
      aaaaa: "10-2751",
      type: "info",
      metricsName: "Faulting Rate",
      msg: "Info",
      utilization: "300 faults/sec",
      page:"/detailed-view-memory"
    },
    {
      id: 11,
      aaaaa: "11-9432",
      type: "warning",
      metricsName: "Ethernet Line Utilization",
      msg: "warning",
      utilization: "54%",
      page:"/detailed-view-memory"
    },
  ];
  return (
    <div className="enterprise_wrapper">
      <SectionHeader
        title={"Metrics"}
        btnClickHandler={handlePrintDetails}
        breadCrumbsList={breadCrumbsList}
      />
      <div className="metrics_card_wrapper">
        {arr.map((item) => (
          <MetricsCard
            key={item.id}
            cardClickHandler={() => handleNavigation(item.metricsName, item.page)}
            {...item}
          />
        ))}
      </div>
    </div>
  );
}

export default MetricsPage;
