import React from "react";

const CoverTemplate1 = (props) => {
    const { logo, report_name, system, report_period, report_date, client_name, width, height, Primary, Secondary, logo_s } = props;
    return (<svg width={width}
        height={height}
        viewBox="0 0 816 1056"
        version="1.1"
        id="svg5">
        <defs id="defs2" />
        <g id="layer1">
            <rect style={{fill:Secondary,fillOpacity:1,stroke:Secondary,strokeWidth:0.87513,strokeOpacity:1}}
                id="color_Secondary"
                width="691.07764"
                height="827.85339"
                x="-381.40567"
                y="125.60638"
                rx="23.305882"
                transform="rotate(-37.009861)" />
            <rect style={{fill:Primary,fillOpacity:1,stroke:'none',strokeWidth:0.87513,strokeOpacity:1}}
                id="colorPrimary"
                width="691.07764"
                height="827.85339"
                x="-492.7814"
                y="90.579781"
                rx="23.305882"
                transform="rotate(-37.009861)" />
            <rect style={{fill:'#ffffff',fillOpacity:1,stroke:'#ffffff',strokeWidth:0.929495,strokeOpacity:1}}
                id="rect9901"
                width="734.0094"
                height="879.28204"
                x="-456.56516"
                y="-0.45963579"
                rx="24.75371"
                transform="rotate(-37.009861)" />
            <image width="229.6983"
                height="45"
                preserveAspectRatio='none'
                style={{imageRendering:'optimizeQuality'}}
                href={logo_s}
                id="app_logo"
                x="580.81451"
                y="1009.6268" />
            <text 
                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'21.3333px',fontFamily:'Trebuchet MS',fill:'#1a1a1a',fillOpacity:1,stroke:'#030000',strokeOpacity:1}}
                x="4.1129503"
                y="1037.7333"
                id="copyright">
                <tspan id="tspan6935" x="4.1129503" y="1037.7333">© 2023 PerfScan. All rights reserved.</tspan>
            </text>
            <text 
                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'48px',fontFamily:'Trebuchet MS',fill:'#1a1a1a',stroke:'#000000',strokeOpacity:1}}
                x="11.991273"
                y="503.32227"
                id="report_name">
                <tspan id="tspan2589"
                    x="11.991273"
                    y="503.32227"
                    style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'48px',fontFamily:'Trebuchet MS',fill:'#1a1a1a'}}>
                    Historical Analysis Report
                </tspan>
            </text>
            <text 
                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'42.6667px',fontFamily:'Trebuchet MS',fill:'#1a1a1a',fillOpacity:1,stroke:'#000000',strokeOpacity:1}}
                x="14.395328"
                y="246.77705"
                id="system_name">
                <tspan id="tspan4577" x="14.395328" y="246.77705">{system}</tspan>
            </text>
            <text 
                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'42.6667px',fontFamily:'Trebuchet MS',fill:'#1a1a1a',fillOpacity:1,stroke:'#030000',strokeOpacity:1}}
                x="15.423565"
                y="548.05066"
                id="report_period">
                <tspan id="tspan4581" x="15.423565" y="548.05066">April 1-30, 2022</tspan>
            </text>
            <text 
                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'32px',fontFamily:'Trebuchet MS',fill:'#1a1a1a',fillOpacity:1,stroke:'#030000',strokeOpacity:1}}
                x="13.006898"
                y="683.77802"
                id="report_date">
                <tspan id="tspan6915"
                    x="13.006898"
                    y="683.77802"
                    style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'32px',fontFamily:'Trebuchet MS',fill:'#1a1a1a',stroke:'#030000',strokeOpacity:1}}>
                    Report Date : 02/13/2023
                </tspan>
            </text>
            <text 
                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'32px',fontFamily:'Trebuchet MS',fill:'#1a1a1a',fillOpacity:1,stroke:'#000000',strokeOpacity:0}}
                x="13.163148"
                y="725.93573"
                id="client_name">
                <tspan id="tspan6919" x="13.163148" y="725.93573">Doug's Golf Supply</tspan>
            </text>
            <rect style={{fill:'#ffffff',fillOpacity:0.6,stroke:'none',strokeWidth:1.01101,strokeLinecap:'round',strokeLineJoin:'round',strokeOpacity:1}}
                id="logo_holder"
                width="816.68604"
                height="98.988983"
                x="0"
                y="30" />
            <image 
                height="100"
                preserveAspectRatio='none'
                style={{imageRendering:'optimizeQuality'}}
                href={logo}
                id="client_logo"
                x="30"
                y="50" />
        </g>
    </svg>
    )
}

export default CoverTemplate1;
