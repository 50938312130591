import React from "react";

function Loading() {
  return (
    <div className="loading">
      <i className="fa fa-circle-o-notch fa-spin"></i>
      <div className="label">loading</div>
    </div>
  );
}

export default Loading;
