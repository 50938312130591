import React from "react";

function MetricsCard(props) {
  const { keyID, type, msg, metricsName, utilization, cardClickHandler } =
    props;
  return (
    <div
      className={`metricsCard ${type}`}
      onClick={cardClickHandler}
      key={keyID}
    >
      <div className="info">
        <div className="title">
          {metricsName}: {true}
          {keyID}
        </div>
        <div className="sub_title">{utilization}</div>
      </div>
      <div className="msg">{msg}</div>
    </div>
  );
}

export default MetricsCard;
