import React from "react";
import SettingHeader from "./SettingHeader";

const ComingSoon = () => {
    return (
        <div className="setting-options">
            <SettingHeader
                iconClass={"fa fa-picture-o"}
                title={"Problem Determination"}
                subTitle={""} />
            <h1>Coming Soon</h1>
        </div>)
}

export default ComingSoon;