import React from "react";
import { APP_URL } from "../../../typeCodes";

const CoverTemplate3 = (props) => {
    const { logo, report_name, system, report_period, report_date, client_name, width, height, Primary, Secondary, logo_s} = props;
    const cover_img = `${APP_URL}cover_3.png`; 

    return (<svg width={width} height={height} viewBox="0 0 215.9 279.4" version="1.1" id="svg14348">
                <defs id="defs14345" />
                    <g id="layer1">
                    <rect style={{fill:'#ffffff',fillOpacity:1,stroke:'#31b900',strokeWidth:0,strokeLineCap:'round',strokeLineJoin:'round'}}
                        id="rect27464"
                        width="215.7168"
                        height="12.936479"
                        x="0.095286168"
                        y="266.46643" />
                        <image width="215.91125" height="143.94083" preserveAspectRatio='none' href={cover_img} id="cover_background" x="0.32000735" y="74.508263" />
                        <rect style={{fill:Primary,fillOpacity:1,stroke:'none',strokeWidth:'0.28783',strokeOpacity:1}}
                            id="rect14420"
                            width="153.40637"
                            height="79.134758"
                            x="62.526363"
                            y="0.13475499" />
                        <rect style={{display:'inline',fill:'#c1c9cd',fillOpacity:'0.799163',stroke:'none',strokeWidth:'0.436361',strokeOpacity:1}}
                            id="rect14420-9"
                            width="153.30676"
                            height="182"
                            x="62.5261"
                            y="79.084" />
                        <rect style={{fill:Secondary,fillOpacity:1,stroke:'none',strokeWidth:'0.226113',strokeOpacity:1}}
                            id="rect15680"
                            width="62.65704"
                            height="79.135002"
                            x="0"
                            y="0.13500001" />
                        <rect style={{fill:Secondary,fillOpacity:'0.8',stroke:'none',strokeWidth:'0.342908',strokeOpacity:1}}
                            id="rect15680-0"
                            width="62.656906"
                            height="182"
                            x="0"
                            y="79.084389" />
                        <rect
                            style={{display:'inline',fill:'#ffffff',fillOpacity:'0.503704',stroke:'none',strokeWidth:'0.294086',strokeLinecap:'round',strokeLineJoin:'round'}}
                            id="logo_holder"
                            width="216.00502"
                            height="26.443619"
                            x="0.007033193"
                            y="7.9375"
                            transform="matrix(1,6.8122544e-5,-2.0456556e-4,0.99999998,0,0)"
                            ry="0" />
                        <image
                        width="35.100891"
                        height="6.8765864"
                        preserveAspectRatio="none"
                        style={{imageRendering:'optimizeQuality'}}
                        href={logo_s}
                        id="app_logo"
                        x="179.213"
                        y="271.73141" />
                        <text style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'4.23333px',fontFamily:'Trebuchet MS',fontVariantCaps:'normal',fontVariantNumeric:'normal',fill:'#03527c',fillOpacity:1,stroke:'#03527c',strokeWidth:'0.264583',strokeOpacity:1}}
                        x="1.4788595"
                        y="276.67178"
                        id="text6937">
                            <tspan
                                id="tspan6935"
                                x="1.4788595"
                                y="276.67178"
                                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'4.23333px',fontFamily:'Trebuchet MS',fontVariantCaps:'normal',fontVariantNumeric:'normal',strokeWidth:'0.264583'}}>
                                © 2023 PerfScan. All rights reserved.
                            </tspan>
                        </text>
                        <text
                            style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'12.7px',fontFamily:'Trebuchet MS',fontVariantCaps:'normal',fontVariantNumeric:'normal',fill:'#000080',stroke:'#000000',strokeWidth:'0.264583',strokeOpacity:1}}
                            x="68.104698"
                            y="128.2444"
                            id="report_title">
                            <tspan
                                id="tspan2589"
                                x="68.104698"
                                y="128.2444"
                                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'12.7px',fontFamily:'Trebuchet MS',fontVariantLigatures:'normal',fontVariantCaps:'normal',fontVariantNumeric:'normal',fill:'#000080',strokeWidth:'0.264583'}}>
                                   {report_name}
                            </tspan>
                        </text>
                        <text
                            style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'11.2889px',fontFamily:'Trebuchet MS',fontVariantLigatures:'normal',fontVariantCaps:'normal',fontVariantNumeric:'normal',fill:'#000080',stroke:'#000000',strokeWidth:'0.264583',strokeOpacity:1}}
                            x="68.104698"
                            y="143.20743"
                            id="system_name">
                            <tspan
                                id="tspan4577"
                                x="68.104698"
                                y="143.20743"
                                style={{strokeWidth:'0.264583'}}>
                                    {system}
                            </tspan>
                        </text>
                        <text
                            style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'11.2889px',fontFamily:'Trebuchet MS',fontVariantLigatures:'normal',fontVariantCaps:'normal',fontVariantNumeric:'normal',fill:'#03527c',fillOpacity:1,stroke:'#03527c',strokeWidth:'0.264583',strokeOpacity:1}}
                            x="68.104698"
                            y="156.5381"
                            id="report_period">
                            <tspan
                                id="tspan4581"
                                x="68.104698"
                                y="156.5381"
                                style={{strokeWidth:'0.264583'}}>
                                    {report_period}
                            </tspan>
                        </text>
                        <text
                            style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'8.46667px',fontFamily:'Trebuchet MS',fontVariantLigatures:'normal',fontVariantCaps:'normal',fontVariantNumeric:'normal',fill:'#03527c',fillOpacity:1,stroke:'#03527c',strokeWidth:'0.264583',strokeOpacity:1}}
                            x="68.648796"
                            y="192.4493"
                            id="report_date">
                            <tspan
                                id="tspan6915"
                                x="68.648796"
                                y="192.4493"
                                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'8.46667px',fontFamily:'Trebuchet MS',fontVariantLigatures:'normal',fontVariantCaps:'normal',fontVariantNumeric:'normal',strokeWidth:'0.264583'}} >
                                    {report_date}
                            </tspan>
                        </text>
                        <text
                            style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'8.46667px',fontFamily:'Trebuchet MS',fontVariantLigatures:'normal',fontVariantCaps:'normal',fontVariantNumeric:'normal',fill:'#03527c',fillOpacity:1,stroke:'#03527c',strokeWidth:'0.264583',strokeOpacity:1}}
                            x="68.648811"
                            y="203.60353"
                            id="text6921">
                            <tspan
                                id="tspan6919"
                                x="68.648811"
                                y="203.60353"
                                style={{strokeWidth:'0.264583'}}>
                                    {client_name}
                            </tspan>
                        </text>
                        <image
                            height="26.458334"
                            preserveAspectRatio="none"
                            style={{imageRendering:'optimizeQuality'}}
                            href={logo}
                            id="client_logo"
                            x="4.8534784"
                            y="11.215698" />
            </g>
        </svg>)
}

export default CoverTemplate3;
