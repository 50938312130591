import React from "react";
import { APP_URL } from "../../../typeCodes";
import { he } from "date-fns/locale";
const CoverTemplate2 = (props) => {
    const { logo, report_name, system, report_period, report_date, client_name, width, height, Primary, Secondary, logo_s} = props;
    const cover_img = `${APP_URL}cover_2.png`; 
    return (<svg
        width={width}
        height={height}
        viewBox="0 0 215.9 279.4"
        version="1.1"
        id="svg465"
       >
         <g id="layer1">
           <image
             width="216.11813"
             height="279.71835"
             preserveAspectRatio='none'
             style={{display:'inline',imageRendering:'optimizeQuality'}}
             href={cover_img}
             id="image448"
             x="0"
             y="1.3877788e-17" />
           <path
             style={{display:'inline',fill:Primary,fillOpacity:1,stroke:Primary,strokeWidth:0,strokeDasharray:'none',strokeOpacity:1}}
             d="M 73.237828,74.033104 -0.03614898,170.26169 -0.00200469,124.99591 Z"
             id="colorPrimary" />
           <path
             style={{display:'inline',mixBlendMode:'normal',fill:Primary,fillOpacity:1,fillRule:'nonzero',stroke:'none',strokeWidth:'0.264583'}}
             d="M 0.38457107,235.54674 46.655261,279.64849 H -0.19381293 Z"
             id="colorPrimaryFooter" />
           <path
             style={{display:'inline',fill:Secondary,fillOpacity:1,stroke:'none',strokeWidth:'0.264583'}}
             d="M 13.881207,248.41577 116.39971,279.64849 46.415287,279.5039 Z"
             id="colorSecondaryFooter" />
           <path
             style={{display:'inline',fill:Secondary,fillOpacity:1,stroke:'none',strokeWidth:0,strokeDasharray:'none',strokeOpacity:1}}
             d="M 78.226373,-2.168883e-4 -7.8465206e-4,124.94477 -1.0442624e-4,124.99593 179.87741,-4.9431719e-5 Z"
             id="colorSecondary" />
           <rect
             style={{fill:'#ffffff',fillOpacity:'0.25',stroke:'none',strokeWidth:0,strokeLinecap:'round',strokeLinejoin:'round',strokeDasharray:'none'}}
             id="logo_holder_client"
             width="215.94089"
             height="17.515928"
             x="1.4642933e-06"
             y="8.3224125"
             ry="0" />
           <image
             width="64.822914"
             height="13.229167"
             preserveAspectRatio="none"
             style={{display:'inline',imageRendering:'optimizeQuality'}}
             href={logo}
             id="client_logo"
             x="149.3456"
             y="11.026118" />
           <image
             width="55.028954"
             height="10.780676"
             preserveAspectRatio="none"
             style={{display:'inline',imageRendering:'optimizeQuality'}}
             href={logo_s}
             id="app_logo"
             x="159.17857"
             y="267.58563" />
           <text
                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'5.64444px',fontFamily:'Trebuchet MS',display:'inline',fill:'#03527c',fillOpacity:1,stroke:'#03527c',strokeWidth:'0.264583',strokeOpacity:1}}
                x="1.372492"
                y="277.51453"
                id="text6937">
            <tspan
                id="tspan6935"
                x="1.372492"
                y="277.51453"
                style={{strokeWidth:'0.264583'}}>© 2023 PerfScan. All rights reserved.
             </tspan>
           </text>
           <text
                style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'11.2889px',fontFamily:'Trebuchet MS',display:'inline',fill:'#042c62',fillOpacity:1,stroke:'#042c62',strokeWidth:'0.264583',strokeOpacity:1}}
                x="43.872192"
                y="173.15656"
                id="text2591">
            <tspan
               id="tspan2589"
               x="43.872192"
               y="173.15656"
               style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'11.2889px',fontFamily:'Trebuchet MS',fill:'#042c62',fillOpacity:1,stroke:'#042c62',strokeWidth:'0.264583',strokeOpacity:1}}>
                {report_name}
             </tspan>
           </text>
           <text
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'11.2889px',fontFamily:'Trebuchet MS',display:'inline',fill:'#042c62',fillOpacity:1,stroke:'#042c62',strokeWidth:'0.264583',strokeOpacity:1}}
             x="44.676956"
             y="124.72782"
             id="text4579">
             <tspan
               id="tspan4577"
               x="44.676956"
               y="124.72782"
               style={{fill:'#042c62',fillOpacity:1,stroke:'#042c62',strokeWidth:'0.264583',strokeOpacity:1}}>
               {system}
             </tspan>
           </text>
           <text
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'11.2889px',fontFamily:'Trebuchet MS',display:'inline',fill:'#042c62',fillOpacity:1,stroke:'#042c62',strokeWidth:0.264583,strokeOpacity:1}}
             x="44.676956"
             y="185.09109"
             id="text4583">
             <tspan
               id="tspan4581"
               x="44.676956"
               y="185.09109"
               style={{fill:'#042c62',fillOpacity:1,stroke:'#042c62',strokeWidth:0.264583,strokeOpacity:1}}>
               {report_period}
             </tspan>
           </text>
           <text
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'8.46667px',fontFamily:'Trebuchet MS',display:'inline',fill:'#31b900',fillOpacity:1,stroke:'#31b900',strokeWidth:0.264583,strokeOpacity:1}}
             x="44.037552"
             y="232.24919"
             id="text6917">
             <tspan
               id="tspan6915"
               x="44.037552"
               y="232.24919"
               style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'8.46667px',fontFamily:'Trebuchet MS',fill:'#31b900',fillOpacity:1,stroke:'#31b900',strokeWidth:0.264583,strokeOpacity:1}}>
                Report Date : {report_date}
             </tspan>
           </text>
           <text
             style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'8.46667px',fontFamily:'Trebuchet MS',display:'inline',fill:'#31b900',fillOpacity:1,stroke:'#31b900',strokeWidth:0.264583,strokeOpacity:1}}
             x="44.078888"
             y="243.40343"
             id="text6921">
             <tspan
               id="tspan6919"
               x="44.078888"
               y="243.40343"
               style={{fill:'#31b900',fillOpacity:1,stroke:'#31b900',strokeWidth:0.264583,strokeOpacity:1}}>
               {client_name}
             </tspan>
           </text>
       </g>
     </svg>    
    )
}

export default CoverTemplate2;
