import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAsyncPeriodCPUUtilization, getCPUUtilizationData } from "../../../../store/slices/periodVsPeriodCharts/periodCharts/periodCPUChartsSlice";
import usePeriodQueryData from "../../../../hooks/usePeriodQueryData";
import * as _ from 'lodash';
import moment from "moment";
import GridLoader from "react-spinners/GridLoader";
import {
  createChartDataMapping,
} from "../../../../helpers/commonHelper";
import ChartView from "../../common/ChartView";
import { findMinMax, getRound } from "../../../../helpers/commonHelper";


const PeriodCpuUtilizationChartPrint = (syncHandler) => {
  const dispatch = useDispatch();
  const cpuData = useSelector(getCPUUtilizationData);
  const filters = useSelector(state => state.filters);
  const pFilter = filters.period_filter; //dates

  let period1 = "";
  let period2 = "";
  let max = 100;
  // Show only start date if both start and end date are same
  const period1_sdate = moment(pFilter[0].sdate).unix()
  const period1_edate = moment(pFilter[0].edate).unix()
  if (period1_sdate === period1_edate) {
    period1 = moment(pFilter[0].sdate).format("MMM Do YYYY")
  } else {
    period1 = moment(pFilter[0].sdate).format("MMM Do YYYY") + ' - ' + moment(pFilter[0].edate).format("MMM Do YYYY");
  }
  // Show only start date if both start and end date are same
  const period2_sdate = moment(pFilter[1].sdate).unix();
  const period2_edate = moment(pFilter[1].edate).unix();
  if (period2_sdate === period2_edate) {
    period2 = moment(pFilter[1].sdate).format("MMM Do YYYY")
  } else {
    period2 = moment(pFilter[1].sdate).format("MMM Do YYYY") + ' - ' + moment(pFilter[1].edate).format("MMM Do YYYY");
  }

  const pqd = usePeriodQueryData();

  useEffect(() => {
    dispatch(fetchAsyncPeriodCPUUtilization(pqd));
  }, [dispatch])

  const cpuUtilization1 = createChartDataMapping(
    cpuData.data[0]?.data || [],
    "stackedColumn",
    "cpuUtilization"
  );
  const cpuUtilization2 = createChartDataMapping(
    cpuData.data[1]?.data || [],
    "stackedColumn",
    "cpuUtilization"
  );

  return (
    <>  {cpuData.loading &&
      <div className="chart_container">
        <div style={{ display: "block", margin: "auto" }}>
          <GridLoader color="#366bd6"/>
        </div>
        <div style={{ display: "block", margin: "auto" }}>
          <GridLoader color="#366bd6"/>
        </div>
      </div>
    }
      {!cpuData.loading && !_.isEmpty(cpuData.data) &&
        <>
          <div className="chart_container">
            <table className="table tableAlign printTable">
              <tbody>
                <tr>
                  {!_.isEmpty(cpuData.data[0].data) &&
                    <ChartView
                      key={"cpuutilization1"}
                      data={cpuUtilization1}
                      title2={`CPU Utilization`}
                      title={cpuData.data[0].params.server}
                      subtitle={period1}
                      yAxisTitle={"Utilization"}
                      xAxisDateFormat={cpuUtilization1[0].xValueFormatString}
                      isVisible={true} 
                      showTotal={true}
                      minimum={0}
                      maximum={max}
                    />

                  }
                  {_.isEmpty(cpuData.data[0].data) &&
                    <div className="chat_main1"> {/* noDataStyle  */}
                      <div style={{textAlign:'center'}}>
                        <h4>CPU Utilization</h4>
                        <img style={{height:'50%',width:'50%'}} src="/noData.webp" alt="No data available for CPU Utilization."/>
                      </div>
                    </div>
                  }
                </tr>
                <tr>
                  {!_.isEmpty(cpuData.data[1].data) &&
                    <ChartView
                      key={"cpuutilization2"}
                      data={cpuUtilization2}
                      title2={`CPU Utilization`}
                      title={cpuData.data[1].params.server}
                      subtitle={period2}
                      yAxisTitle={"Utilization"}
                      xAxisDateFormat={cpuUtilization2[0].xValueFormatString}
                      isVisible={true}
                      showTotal={true}
                      minimum={0}
                      maximum={max}
                    />
                  }
                  {_.isEmpty(cpuData.data[1].data) &&
                    <div className="chat_main1"> {/* noDataStyle  */}
                      <div style={{textAlign:'center'}}>
                        <h4>CPU Utilization</h4>
                        <img style={{height:'50%',width:'50%'}} src="/noData.webp" alt="No data available for CPU Utilization."/>
                      </div>
                    </div>
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </>
      }
    </>
  )
}

export default PeriodCpuUtilizationChartPrint;