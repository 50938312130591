import React from "react";
import { Outlet } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";

function Settings() {
  return (
    <Outlet />
  );
}

export default Settings;
