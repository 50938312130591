import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseAPI from '../../../services/baseApi';


export const fetchAsyncCPConfig = createAsyncThunk("appconfig/fetchAsyncCPConfig", async(qd) => {
    const response = await baseAPI.get(`/api/appconfig/cp`);
    return response.data;
});

export const updateCPConfig = createAsyncThunk("appconfig/updateCPConfig", async({ id, qd }) => {
  const response = await baseAPI.put(`/api/appconfig/${id}`, qd);
  return response.data;
});

const initialState = {
    cpConfig : {loading:false, data:[], error:''},
    updConfig : {loading:false, data:[], error: ''}
}
const AppConfigSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder.addCase(fetchAsyncCPConfig.pending, state => {
        state.cpConfig.loading = true
      })
      builder.addCase(fetchAsyncCPConfig.fulfilled, (state, action) => {
        state.cpConfig.loading = false
        state.cpConfig.data = action.payload
        state.cpConfig.error = ''
      })
      builder.addCase(fetchAsyncCPConfig.rejected, (state, action) => {
        state.cpConfig.loading = false
        state.cpConfig.data = []
        state.cpConfig.error = action.error.message
      })
      builder.addCase(updateCPConfig.pending, state => {
        state.updConfig.loading = true
      })
      builder.addCase(updateCPConfig.fulfilled, (state, action) => {
        state.updConfig.loading = false
        state.updConfig.data = action.payload
        state.updConfig.error = ''
      })
      builder.addCase(updateCPConfig.rejected, (state, action) => {
        state.updConfig.loading = false
        state.updConfig.data = []
        state.updConfig.error = action.error.message
      })
    }
})

export const getCapacityConfig = (state) => state.appConfig.cpConfig;
export const updateConfig = (state) => state.appConfig.updConfig;

export default AppConfigSlice.reducer;