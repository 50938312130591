import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getParametersFromLocalStorage, handlePrintDetails } from "../../helpers/commonHelper";
import MetricsCard from "../components/MetricsCardSys";
import SectionHeader from "../components/SectionHeader";
import _ from "lodash";
import EditGroupModal from "../components/EditGroupModal";
import { Fragment } from "react";

function EnterPriseServerView() {
  const [data, setData] = useState([]);
  const [activeGroup, setActiveGroup] = useState(null);
  const [groupByType, setGroupByType] = useState("type");
  const [badgeType, setbadgeType] = useState("");
  const [isEditGroup, setisEditGroup] = useState(false);

  // const { data: servers, isSuccess, isLoading, isError } = useGetServersQuery();

  let navigate = useNavigate();
  const handleNavigation = (id) => {
    navigate({
      pathname: "/server",
      search: `?id=${id}`,
    });
  };
  let arr = [
    {
      id: "7885FE0",
      type: "critical",
      msg: "Critical",
      health: "90%",
      serverLocation: "Chicago, IL",
      serverName: "AMERICAS - ATLANTA",
      country: "USA",
      region: "IL",
      state: "IL",
      department: "IT",
      orderEntry: "Order-Entry",
      sortOrder:1,
    },

    {
      id: "78D1440",
      serverName: "COLUMBUS FAIR",
      type: "critical",
      state: "Lower Saxony, Germany",
      country: "Germany",
      region: "Europe",
      department: "IT",
      orderEntry: "Order-Entry",
      msg: "Critical",
      health: "50%",
      serverLocation: "Chicago, IL",
      sortOrder:1,
    },
    {
      id: "78AB530",
      serverName: "DES MOINES AA",
      type: "critical",
      state: "Bavaria, Germany",
      country: "Germany",
      region: "Europe",
      department: "IT",
      orderEntry: "Order-Entry",
      msg: "critical",
      health: "90%",
      serverLocation: "St. Louis, MO",
      sortOrder:1,
    },
    {
      id: "21DBBEW",
      serverName: "DEALERS AA-IDAHO",
      type: "critical",
      state: "Lower Saxony, Germany",
      country: "Germany",
      region: "Europe",
      department: "IT",
      orderEntry: "Order-Entry",
      serverLocation: "St. Louis, MO",
      msg: "Critical",
      health: "90%",
      sortOrder:1,
    },
    {
      id: "786F52X",
      serverName: "MORTON AA",
      type: "critical",
      state: "Lower Saxony, Germany",
      country: "Germany",
      region: "Europe",
      department: "IT",
      orderEntry: "Order-Entry",
      serverLocation: "St. Louis, MO",
      msg: "Critical",
      health: "90%",
      sortOrder:1,
    },
    {
      id: "217731W",
      serverName: "QUINCY AA",
      type: "warning",
      state: "Bavaria, Germany",
      country: "Germany",
      region: "Europe",
      department: "IT",
      orderEntry: "Order-Entry",
      serverLocation: "St. Louis, MO",
      msg: "warning",
      health: "90%",
      sortOrder:2,
    },
    {
      id: "211AB2W",
      serverName: "DYER AA, INC.",
      type: "warning",
      state: "Bavaria, Germany",
      country: "Germany",
      region: "Europe",
      department: "IT",
      orderEntry: "Order-Entry",
      msg: "warning",
      serverLocation: "St. Louis, MO",
      health: "90%",
      sortOrder:2,
    },
    {
      id: "782F82X",
      serverName: "AMERICAS - I94",
      type: "warning",
      state: "Bavaria, Germany",
      country: "Germany",
      region: "Europe",
      department: "IT",
      orderEntry: "Order-Entry",
      msg: "warning",
      serverLocation: "St. Louis, MO",
      health: "90%",
      sortOrder:2,
    },
    {
      id: "7885A3X",
      serverName: "AMERICAS-TULSA",
      type: "warning",
      state: "Bavaria, Germany",
      country: "Germany",
      region: "Europe",
      department: "IT",
      orderEntry: "Order-Entry",
      msg: "warning",
      serverLocation: "St. Louis, MO",
      health: "90%",
      sortOrder:2,
    },
    {
      id: "786EEF0",
      serverName: "KESLER-SCHAEFER",
      type: "warning",
      state: "Bavaria, Germany",
      country: "Germany",
      region: "Europe",
      department: "IT",
      orderEntry: "Order-Entry",
      msg: "warning",
      serverLocation: "St. Louis, MO",
      health: "90%",
      sortOrder:2,
    },
    {
      id: "7851590",
      serverName: "NORTHEAST AA",
      type: "good",
      state: "Kotoor, Africa",
      country: "Egypt",
      region: "Africa",
      department: "IT",
      orderEntry: "Order-Entry",
      msg: "good",
      serverLocation: "St. Louis, MO",
      health: "90%",
      sortOrder:3,
    },
    {
      id: "7885F70",
      serverName: "AMERICAS - DALLAS",
      type: "good",
      msg: "Good",
      health: "90%",
      serverLocation: "Chicago, IL",
      country: "India",
      region: "Asia",
      state: "Bangalore, India",
      department: "IT",
      orderEntry: "Order-Entry",
      sortOrder:3,
    },
    {
      id: "78EEAE0",
      serverName: "FOX VALLEY AA",
      type: "good",
      msg: "Good",
      health: "50%",
      serverLocation: "Chicago, IL",
      country: "Singapore",
      region: "Asia",
      state: "Bangalore, India",
      department: "IT",
      orderEntry: "Order-Entry",
      sortOrder:2,
    },

    {
      id: "78A772X",
      serverName: "AMERICAS - BOSTON",
      type: "good",
      state: "Kotoor, Africa",
      country: "Egypt",
      region: "Africa",
      department: "IT",
      orderEntry: "Order-Entry",
      msg: "good",
      health: "90%",
      serverLocation: "St. Louis, MO",
      sortOrder:3,
    },
    {
      id: "21E43CV",
      serverName: "AKRON AA",
      type: "good",
      state: "Kotoor, Africa",
      country: "Egypt",
      region: "Africa",
      department: "IT",
      orderEntry: "Order-Entry",
      msg: "good",
      serverLocation: "St. Louis, MO",
      health: "90%",
      sortOrder:3,
    },
    
  ];
  let groupOptionsList = [
    {
      id: 3,
      value: "serverName",
      viewText: "Server Serial No.",
    },
    {
      id: 1,
      value: "type",
      viewText: "Server Health Type",
    },
  ];
  useEffect(() => {
    let items = _.chain(arr)
      .groupBy(groupByType)
      .map((value, key) => ({ key: key, dataSet: value }))
      .value();
    setData(items);
  }, [groupByType]);

  //toggle group to view card of that category
  const toggleGroups = (id) => {
    setbadgeType("");
    if (activeGroup === id) {
      setActiveGroup(null);
      return;
    } else {
      setActiveGroup(id);
    }
  };
  // show selected type servers eg. good, critical
  const badgeClickHandler = (e, type, activeGroupID) => {
    e.stopPropagation();
    setbadgeType(type);
    setActiveGroup(activeGroupID);
  };

  const renderbadge = (dataItem) => {
    return (
      <div className="badge_spotlight">
        <span className="badge">{Number(dataItem?.dataSet?.length)}</span>
      </div>
    );
  };

  // render badge with Number
  const renderAllbadge = (dataItem, activeGroupID) => {
    let filterdData = dataItem.dataSet;
    const dataList = _.chain(filterdData)
      .groupBy("type")
      .map((value, key) => ({ key: key, dataSet: value }))
      .value();
    return dataList.map((item) => {
      return (
        <div
          className={`badge_count_name ${item.key}`}
          onClick={(e) => badgeClickHandler(e, item.key, activeGroupID)}
          key={item.id}
        >
          <span className="badge">{Number(item?.dataSet?.length)}</span>
          <span className="badge-text">{item.key}</span>
        </div>
      );
    });
  };

  const editGroup = (e, data) => {
    e.stopPropagation();
    setisEditGroup((isEditGroup) => !isEditGroup);
  };
  const deleteGroup = (e) => {
    e.stopPropagation();
  };

  const checkUserType = () => {
    if (groupByType === "type" || groupByType === "serverName") return false;
    return true;
  };


  return (
    <div className="enterprise_wrapper">
      <SectionHeader
        title={"Enterprise"}
        btnClickHandler={handlePrintDetails}
      />
      <Fragment>
        <div className="enterprise_edit_options_wrap">
          <div className="options-left"></div>
          <div className="options-right">
            <div className="form-group">
              <select
                name="groupby"
                id="groupitems"
                className="form-control"
                onChange={(e) => setGroupByType(e.target.value)}
                defaultValue={groupByType}
              >
                <option disabled>--Select Group Type--</option>
                {groupOptionsList?.map((type, index) => (
                  <option key={index} value={type.value}>
                    {type.viewText}
                  </option>
                ))}
              </select>
            </div>
            <div className="add-options">
              <button className="btn btn-primary" onClick={(e) => editGroup(e)}>
                +{"  "} Add Group
              </button>
            </div>
          </div>
        </div>
        <div className="servers_wrapper">
          {data?.map((dataItem, index) => (
            <div className="server_parent" key={index}>
              <div
                className={`group_title ${dataItem.key}`}
                onClick={() => toggleGroups(index)}
              >
                <div className="left-sec">
                  {groupByType === "type" && renderbadge(dataItem)}{" "}
                  <i
                    className={
                      "fa " +
                      (activeGroup === index
                        ? "fa-angle-down"
                        : "fa-angle-right")
                    }
                  ></i>
                  {dataItem.key}{" "}
                  {groupByType !== "type" && renderAllbadge(dataItem, index)}
                </div>
                {checkUserType() ? (
                  <div className="right-sec">
                    <button
                      className="btn btn-icon btn-icon-secondary"
                      onClick={(e) => editGroup(e, dataItem)}
                    >
                      <i className="pi pi-pencil"></i>
                    </button>
                    <button
                      className="btn btn-icon btn-icon-danger"
                      onClick={deleteGroup}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                ) : null}
              </div>
              <div
                className={
                  "metrics_card_wrapper " +
                  (activeGroup === index ? "" : "d-none")
                }
              >
                {dataItem &&
                  dataItem.dataSet.length &&
                  dataItem.dataSet
                    .filter((item) => {
                      if (badgeType) {
                        return item.type === badgeType;
                      } else {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <MetricsCard
                        key={index}
                        keyID={item.id}
                        cardClickHandler={() =>
                          handleNavigation(item.serverName)
                        }
                        {...item}
                      />
                    ))}
              </div>
            </div>
          ))}
          <div className="add-options">
            <button className="btn btn-primary" onClick={(e) => editGroup(e)}>
              +{"  "} Add Category
            </button>
          </div>
        </div>
      </Fragment>
      {isEditGroup && (
        <EditGroupModal
          isEditGroup={isEditGroup}
          onModalClose={() => setisEditGroup((isEditGroup) => !isEditGroup)}
        />
      )}
    </div>
  );
}

export default EnterPriseServerView;
