import React, { useEffect, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { fetchAsyncMemorySizeVsFaultingReports, getMemoryVsFaultingDataReports} from "../../../../store/slices/reports/DataReportCharts/memoryReportChartsSlice";
import {
  createChartDataMapping,
    getDataPointsForPoolNumBasedCharts
} from "../../../../helpers/commonHelper";
  import {DISK_OPERATIONS} from '../../../../typeCodes/index';
import ChartView from "../../common/ChartView";
import ChartViewTrend from "../../common/ChartViewTrend";
import * as _ from 'lodash';
import GridLoader from "react-spinners/GridLoader";

const MemVsFaultingReport = ({ activeChartView,reportId }) => {
  const dispatch = useDispatch();
  const memoryVsFaultingData = useSelector(getMemoryVsFaultingDataReports);

  const [checkData, setCheckData] = useState(false); //new

  const [memVsFaultDataPoints, setMemVsFaultDataPoints] = useState([]);
  const [allMemVsFaultPools, setAllMemVsFaultPools] = useState([]);
  const [pNumChangeMemVsFault, setPNumChangeMemVsFault] = useState([]);

  useEffect(() => {
    dispatch(fetchAsyncMemorySizeVsFaultingReports(reportId));
    }, [dispatch])

  useEffect(() => {
    if (memoryVsFaultingData?.data?.data) {
          createChartDataMappingForMemoryVSFault(
              'All'
              );
    }

        if(memoryVsFaultingData.loading === false && !_.isEmpty(memoryVsFaultingData.data.data)){
      if (memoryVsFaultingData.data.data.length === 0) {
        setCheckData(false);
          }
          else{
              setCheckData(true)
      }
    }
    },[memoryVsFaultingData])

  const createChartDataMappingForMemoryVSFault = async (indx) => {
      //debugger;
    let memVsFaultData = memoryVsFaultingData;
    let memorySizeVsFaultingData = {};
    let poolListWithAllTag = new Array();
    memorySizeVsFaultingData = memVsFaultData.data || {};
          poolListWithAllTag = [...memVsFaultData.data.pools,{value:"All",label:"All Pools"}]
    setAllMemVsFaultPools(poolListWithAllTag);

         if(indx == 'All'){
      setPNumChangeMemVsFault(indx);
      let originaldata = [];
      let poolNums = [];
      let poolStringValues = poolListWithAllTag;
      for (let i = 0; i < poolStringValues.length; ++i) {
        if (poolStringValues[i].value != "All") {
          poolNums[i] = Number(poolStringValues[i].value);
        }
      }
          
          originaldata = memorySizeVsFaultingData.data["All"];

          const data_Faulting = getDataPointsForPoolNumBasedCharts(originaldata, "faulting_rate");
          const data_memory_size = getDataPointsForPoolNumBasedCharts(originaldata, "memory_size");

      let chartDataMemVsFault = [
        {
          ...DISK_OPERATIONS,
              type: 'stackedColumn',
          dataPoints: data_Faulting,
          color: "#b81f0a",
          name: "Fault / Sec",
        },
        {
          ...DISK_OPERATIONS,

          type: "stepLine",
          showInLegend: true,
          axisYType: "secondary",
          xValueFormatString: "####",
          axisYIndex: 1,
          dataPoints: data_memory_size,
          color: "#1EB294",
          name: "Total Memory Size (GB)",
        },
      ];
      setMemVsFaultDataPoints(chartDataMemVsFault);
         }
         else{    
      setPNumChangeMemVsFault(indx);
      let originaldata = memorySizeVsFaultingData.data[indx];
          const data_Faulting = getDataPointsForPoolNumBasedCharts(originaldata, "faulting_rate");
          const data_memory_size = getDataPointsForPoolNumBasedCharts(originaldata, "memory_size");
      let chartDataMemVsFault = [
        {
          ...DISK_OPERATIONS,
              type: 'stackedColumn',
          dataPoints: data_Faulting,
          color: "#b81f0a",
          name: "Fault / Sec",
        },
        {
          ...DISK_OPERATIONS,

          type: "stepLine",
          showInLegend: true,
          axisYType: "secondary",
          xValueFormatString: "####",
          axisYIndex: 1,
          dataPoints: data_memory_size,
          color: "#1EB294",
          name: "Total Memory Size (GB)",
        },
      ];
      setMemVsFaultDataPoints(chartDataMemVsFault);
    }
      }


  const memoryVsFaultingDataTrends = createChartDataMapping(
    memoryVsFaultingData.data.trend || [],
    "stackedColumn",
    "memorySizeVsFaultingTrends"
  );

  return (
    <>
            
              {memoryVsFaultingData.loading &&
        <div className="chart_container">
          <div style={{ display: "block", margin: "auto" }}>
            <GridLoader color="#366bd6"/>
          </div>
          <div style={{ display: "block", margin: "auto" }}>
            <GridLoader color="#366bd6"/>
          </div>
        </div>
                }
                {!memoryVsFaultingData.loading && !_.isEmpty(memoryVsFaultingData.data.data) && (checkData) &&
          <>
            <>
              <span className="chart_dropDown">
                <p className="chart_dropDown_name">Select Pool Number</p>
                <Dropdown
                  value={pNumChangeMemVsFault}
                  options={allMemVsFaultPools}
                          onChange={(e) => createChartDataMappingForMemoryVSFault(e.value)}
                  placeholder="Select a Pool Number"
                />
              </span>
            </>
            <div className="chart_container" style={{ pageBreakAfter: "always" }}>
              {activeChartView.isMetricsChart && (
                <ChartView
                  data={memVsFaultDataPoints}
                  title={"Memory Size vs Faulting"}
                  yAxisTitle={"Fault / Sec"}
                  xAxisDateFormat={memVsFaultDataPoints[0].xValueFormatString}
                  isVisible={true}
                  showTotal={false}
                  minimum={0}
                />
              )}
              {activeChartView.isTrendsChart && (
                <ChartViewTrend
                  data={memoryVsFaultingDataTrends}
                  title={"Memory Size vs Faulting with Trends"}
                  yAxisTitle={"Fault / Sec"}
                  isVisible={activeChartView.isTrendsChart}
                  xAxisDateFormat="MMM YYYY"
                  showTotal={false}
                  minimum={0}
                />
              )}
            </div>
          </>
                }

              {!memoryVsFaultingData.loading && _.isEmpty(memoryVsFaultingData.data.data) && (checkData === false)  && activeChartView.isMetricsChart && activeChartView.isTrendsChart &&
          <div className="chart_container">
              <div className="chat_main1">  {/* noDataStyle  */}
                    <div style={{textAlign:'center'}}>
                      <h4>Memory size Vs Faulting</h4>
                      <img style={{height:'50%',width:'50%'}} src="/noData.webp" alt="No data available for Memory size vs faulting."/>
                    </div>
              </div>
              <div className="chat_main1">  {/* noDataStyle  */}
                    <div style={{textAlign:'center'}}>
                      <h4>Memory size Vs Faulting</h4>
                      <img style={{height:'50%',width:'50%'}} src="/noData.webp" alt="No data available for Memory size vs faulting."/>
                    </div>
              </div>
          </div>
                }

                {!memoryVsFaultingData.loading && _.isEmpty(memoryVsFaultingData.data.data) && (checkData === false)  && activeChartView.isMetricsChart && (activeChartView.isTrendsChart === false) &&
          <div className="chart_container">
                <div className="chat_main1">  {/* noDataStyle  */}
                    <div style={{textAlign:'center'}}>
                      <h4>Memory size Vs Faulting</h4>
                      <img style={{height:'50%',width:'50%'}} src="/noData.webp" alt="No data available for Memory size vs faulting."/>
                    </div>
                </div>
          </div>
                }
                 {!memoryVsFaultingData.loading && _.isEmpty(memoryVsFaultingData.data.data) && (checkData === false)  && (activeChartView.isMetricsChart === false) && (activeChartView.isTrendsChart) &&
          <div className="chart_container">
                <div className="chat_main1">  {/* noDataStyle  */}
                    <div style={{textAlign:'center'}}>
                      <h4>Memory size Vs Faulting</h4>
                      <img style={{height:'50%',width:'50%'}} src="/noData.webp" alt="No data available for Memory size vs faulting."/>
                    </div>
                </div>
          </div>
                }
    </>
    )
}

export default MemVsFaultingReport;