import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Input,
  Label,
  TabContent,
  TabPane,
  Radio
} from "reactstrap";
import { Toast } from 'primereact/toast';
import {Button} from 'primereact/button'
import { Tooltip } from 'primereact/tooltip';
import { FileUpload } from 'primereact/fileupload';
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector, useDispatch } from "react-redux";
import { ScrollPanel } from 'primereact/scrollpanel';

import { Navigation } from "swiper";

import { getParametersFromLocalStorage } from "../../../helpers/commonHelper";
import * as _ from "lodash"; 

// Templates 
import CoverTemplate1 from "../../components/Branding/CoverTemplate1";
import CoverTemplate2 from "../../components/Branding/CoverTemplate2";
import CoverTemplate3 from "../../components/Branding/CoverTemplate3";
// End Templates 

import ColorPicker from "../../components/common/ColorPickerF";
import RadioCard from "../../components/common/RadioCard";
import Example from "../../components/SelectableCard/Example";

import SettingHeader from "./SettingHeader";
import { API_URL } from "../../../typeCodes";
import {fetchAsyncLogos, fetchAsyncConfig, saveAsyncConfig, getBrandingData, 
    getBrandConfigData, setBrandConfigData, deleteAsyncLogo, delLogoData} from "../../../store/slices/branding/"
//import * as imgsall from "../../../assets"

//const imgs = require.context("../../../assets", false, /\.(png|jpe?g|svg)$/);




import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'

 
const ManageBranding = () => {
    const [template, setTemplate] = useState(null);
    const [colorPrimary, setColorPrimary] = useState('#042960');
    const [colorSecondary, setColorSecondary] = useState('#80BFF3');
    const [selLogoPrimary, setSelLogoPrimary] = useState(null);
    const [selLogoSecondary, setSelLogoSeondary] = useState(null);
    const [selLogoNamePrimary, setSelLogoNamePrimary] = useState(null);
    const [selLogoNameSecondary, setSelLogoNameSeondary] = useState(null);
    const [selLogoPathP, setSelLogoPathP] = useState(null);
    const [selLogoPathS, setSelLogoPathS] = useState(null);
    const [upload, setUpload] = useState(false);
    const [logoData,setLogoData] = useState(null);
    const [configData, setConfigData] = useState(null);
    const [rptType, setRptType] = useState('historical data');

    const toast = useRef(null);

    const userID = getParametersFromLocalStorage("userID");
    const uploadURL = API_URL + `api/upload/${userID}/type/logo`;

    const dispatch = useDispatch();
    const logos = useSelector(getBrandingData);
    const brandConfig = useSelector(getBrandConfigData);
    const saveConfigData = useSelector(setBrandConfigData);
    const deleteLogoData = useSelector(delLogoData);


    const onUpload = () => {
        setUpload(true);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded Successfully' });
    };

    const SaveConfig = () => {
        if(selLogoPrimary === null){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please pick a logo' });
            return;
        }
        if(template === null){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please select a template' });
            return;
        }
        if(colorPrimary === '#042960' && colorSecondary === '#80BFF3'){
            toast.current.show({ severity: 'info', summary: 'Info', detail: 'No color selected, default colors used' });
        }
        const regex = /([a-zA-z]:(?:[\\|\/][a-zA-Z\d-_. ]+)*)([\\|\/])([a-zA-Z\d-_]+\.[a-zA-z]+)/g;
        const data = {
            "logo":selLogoPrimary,
            "logo_s":selLogoSecondary,
            "logo_name":selLogoNamePrimary,
            "logo_s_name":selLogoNameSecondary,
            "logo_path_p":_.replace(selLogoPathP, regex),
            "logo_path_s":_.replace(selLogoPathS, regex),
            "userid": userID,
            "rptType": rptType,
            "primaryColor": colorPrimary,
            "secondaryColor": colorSecondary,
            "selTemplate": template
        }
        dispatch(saveAsyncConfig(data));
    }
    
    const getColorPrimary = (color) => {
        setColorPrimary(color)
    };

    const getColorSecondary = (color) => {
        setColorSecondary(color)
    };

    const getLogoPrimary = (logo) =>{
        setSelLogoPrimary(logo);
    }

    const getLogoSecondary = (logo) =>{
        setSelLogoSeondary(logo);
    }

    const getLogoNamePrimary = (logoname) =>{
        setSelLogoNamePrimary(logoname);
    }

    const getLogoNameSecondary = (logoname) =>{
        setSelLogoNameSeondary(logoname);
    }

    const getLogoPathP = (logoPath) => {
        setSelLogoPathP(logoPath);
    }

    const getLogoPathS = (logoPath) => {
        setSelLogoPathS(logoPath);
    }

    const delLogo = (sellogo) => {
        dispatch(deleteAsyncLogo({userID, sellogo}))
        //alert(`logo is ${logo}`);
    }

    useEffect(() => {
        dispatch(fetchAsyncLogos(userID)) 
    }, [dispatch, upload, deleteLogoData]);

    useEffect(() => {
        if(!logos.loading && logos.data){
        setLogoData(logos.data)
        }
    },[logos])

    useEffect(() => {
        dispatch(fetchAsyncConfig(userID))
    },[dispatch])

    useEffect(() => {
        if(!brandConfig.loading && brandConfig.data.data){
            setConfigData(brandConfig.data.data[0]);
        }
    },[brandConfig])

    useEffect(() => {
        if(!saveConfigData.loading && saveConfigData.data && saveConfigData.data === 'logo set'){
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Settings Saved' });
            return;
        }
    },[saveConfigData]);
   
    useEffect(()=> { 
        //console.log({configData})    
        setColorPrimary(configData?.color_primary);
        setColorSecondary(configData?.color_secondary);
        setTemplate(configData?.sel_template);
        setSelLogoPrimary(configData?.sel_logo);
        setSelLogoSeondary(configData?.sel_logo_s);
        setSelLogoNamePrimary(configData?.sel_logo_name);
        setSelLogoNameSeondary(configData?.sel_logo_s_name);
        setSelLogoPathP(configData?.sel_logo_path);
        setSelLogoPathS(configData?.sel_logo_s_path);
    },[configData])
    return (
        <div className="setting-options">
            <Toast ref={toast} position="top-right" />
            <Tooltip target=".help">
                <p>
                    Logo height will be adjusted automatically to 100px, Logo should be transparent.
                </p>
            </Tooltip>
            <SettingHeader
                iconClass={"fa fa-picture-o"}
                title={"Branding"}
                subTitle={""}
            />
            <div className="branding" id="manageBranding">
                        <div className="manage-branding">
                            <div className="add-branding">
                                <div className="sidebar-title">Logo and Colors <span className="help"> ! </span></div>
                                    <div className="form-options">
                                        <div className="form_group">
                                        <label className="label">Upload Logo</label>
                                            <FileUpload mode="basic" name="file" url={uploadURL} accept="image/*" maxFileSize={1000000} onUpload={onUpload} auto chooseLabel="Browse"/>
                                        </div>
                                        <div className="form_group">
                                        <label className="label">Choose Primary Color</label>
                                        {configData &&
                                            <ColorPicker selColor={colorPrimary} setParentColor={getColorPrimary}/>
                                        }
                                        </div>
                                        <div className="form_group">
                                        <label className="label">Choose Secondary Color</label>
                                        {configData &&
                                            <ColorPicker selColor={colorSecondary} setParentColor={getColorSecondary}/>
                                        }
                                        </div>
                                        <div className="form_group">
                                            <Button onClick={SaveConfig} style={{marginTop: '5%'}}>Save Settings</Button>
                                        </div>
                                    </div>
                                    <div className="form-options">
                                        {/*<BrandTemplate Primary={colorPrimary} Secondary={colorSecondary} width="400" height="400"/>*/}
                                    </div>
                                </div>
                            </div>
                            
                            <div style={{display: "flex",flexDirection: "row"}}>
                                <div style={{display: "flex",flexDirection: "column", width:'500px'}}>
                                    <ScrollPanel style={{ width: '100%', height: '300px' }} >
                                    {logoData && configData &&  
                                        <RadioCard type="PrimaryLogo" data={logoData} selLogo={getLogoPrimary} selLogoName={getLogoNamePrimary} delLogo={delLogo} title="Select Primary Logo" curLogo={configData.sel_logo_name} selLogoPath={getLogoPathP} />
                                    }
                                    </ScrollPanel>
                                    <ScrollPanel style={{ width: '100%', height: '300px' }} >
                                    {logoData && configData && 
                                        <RadioCard type="SecondaryLogo" data={logoData} selLogo={getLogoSecondary} selLogoName={getLogoNameSecondary} delLogo={delLogo} title="Select Secondary Logo" curLogo={configData.sel_logo_s_name} selLogoPath={getLogoPathS} />
                                    }
                                    </ScrollPanel>    
                                </div>
                                <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                                    <SwiperSlide>
                                        {configData &&
                                        <>
                                        <CoverTemplate1 
                                            logo = {selLogoPrimary}
                                            logo_s = {selLogoSecondary}
                                            report_name = "Historical Analysis Report"
                                            system = "Greymine"
                                            report_period = "April 1-30, 2022"
                                            report_date = "02/25/2023"
                                            client_name = "Doug's Golf Supply"
                                            Primary={colorPrimary} Secondary={colorSecondary} height="500" width="300" />
                                        <span style={{display:"block"}}>{template === 'Template1' ? 'SELECTED' :  <Button label="Choose Template 1" onClick={()=>setTemplate("Template1")}/>}</span>
                                        </>}
                                    </SwiperSlide>
                                    <SwiperSlide>
                                    {configData &&
                                        <>
                                        <CoverTemplate2 
                                            logo = {selLogoPrimary}
                                            logo_s = {selLogoSecondary}
                                            report_name = "Historical Analysis Report"
                                            system = "Greymine"
                                            report_period = "April 1-30, 2022"
                                            report_date = "02/25/2023"
                                            client_name = "Doug's Golf Supply"
                                            Primary={colorSecondary} Secondary={colorPrimary} height="500" width="300" />
                                        <span style={{display:"block"}}>{template ==="Template2" ? 'SELECTED' : <Button label="Choose Template 2" onClick={()=>setTemplate("Template2")}/>}</span>
                                        </>}
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        {configData &&
                                        <>
                                        <CoverTemplate3 
                                            logo = {selLogoPrimary}
                                            logo_s = {selLogoSecondary}
                                            report_name = "Historical Analysis Report"
                                            system = "Greymine"
                                            report_period = "April 1-30, 2022"
                                            report_date = "02/25/2023"
                                            client_name = "Doug's Golf Supply"
                                            Primary={colorPrimary} Secondary={colorSecondary} height="500" width="300" />
                                            <span style={{display:"block"}}>{template === 'Template3' ? 'SELECTED' : <Button label="Choose Template 3" onClick={()=>setTemplate("Template3")}/>}</span>
                                        </>}
                                    </SwiperSlide>
                                </Swiper>
                           </div>
                
               {/* <embed
    src={"http://localhost:8080/api/renderPDF"}
    type="application/pdf"
    height={800}
    width={500}
/>*/}
              
                
            </div>
        </div>
    )
};

export default ManageBranding;