import { useSelector, useDispatch } from "react-redux";
import { getParametersFromLocalStorage } from "../../../../helpers/commonHelper";
import { getUuidData } from "../../../../store/slices/reports/SaveNewReport/SaveNewReport";

const usePDQueryData = () => {
    const filters = useSelector(state => state.filters);
    const userId = getParametersFromLocalStorage("userID");
    const uuid = useSelector(getUuidData);


    const queryData = {
        ...(filters.problem_determination_date_filter.sdate && { sdate: filters.problem_determination_date_filter.sdate }),
        ...(filters.problem_determination_date_filter.edate && { edate: filters.problem_determination_date_filter.edate }),
        ...(filters.problem_determination_date_filter.stime && { stime: filters.problem_determination_date_filter.stime }),
        ...(filters.problem_determination_date_filter.etime && { etime: filters.problem_determination_date_filter.etime }),
        // ...(filters.date_filter.pd && { sdate: filters.date_filter.pd.value }),
        ...(filters.system_filter && { sysid: filters.system_filter.id}),
        userId : userId,
        ...(filters.system_filter && {sysname: filters.system_filter.entity_name}),
        rptType:'problem determination',
        ...({fileName: filters.problem_determination_save_filter.filename}),
        ...({saveReport: filters.problem_determination_save_filter.saveReport+""}),
        ...(uuid.data.uniqueid && {uniqueid: uuid.data.uniqueid})
    };

    return queryData;
}

export default usePDQueryData;