import React, {useState} from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


const SysByCPW = (props) => {
    const [selectedSystem, setSelectedSystem] = useState(null);

    const setSys = (sys) => {
        props.setData(sys);
        setSelectedSystem(sys);
    }
   
    return (
        <div className="build_para_card_sys">
            <div className="build_title">Proposed No. of Systems Shown : {props.params.limit}</div>
            <div className="build_title">Peak CPW : {props.peak} </div>
            <div className="systems">
            <DataTable value={props.data} 
                selectionMode={true} 
                selection={selectedSystem} 
                onSelectionChange={(e) => setSys(e.value)} 
                dataKey="m_id" 
                tableStyle={{ minWidth: '80rem' }}
                paginator rows={5} 
                rowsPerPageOptions={[5, 10, 25, 50]}>
                <Column field="m_ptype" header="Type" sortable />
                <Column field="m_model" header="Model" sortable />
                <Column field="m_cpw" header="CPW Rating (Frame)" sortable />
                <Column field="m_ncores" header="Activated No. of Cores" sortable />
                <Column field="proposed_lpar_cores" header="Proposed LPAR Cores" sortable />
                <Column field="proposed_cpw" header="Proposed CPW" sortable />
                <Column field="perc_change" header="Growth Capacity (Peak PFG) %" sortable/>
                <Column selectionMode="single" headerStyle={{ width: '3rem' }} />
            </DataTable>
            </div>
        </div>
    )
}

export default SysByCPW;